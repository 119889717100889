@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

// STYLES

.ff_flex {
  display: flex;
}

.ff_center-align{
  display: flex;
  align-items: center;
}

.ff_inlineflex {
  display: inline-flex;
}

.relative {
  position: relative;
}

.ff_flex_space_bw {
  display: flex;
  justify-content: space-between;
}

.ff_align_center {
  align-items: center;
}

.ff_align_baseline {
  align-items: baseline;
}

.ff_justify_flex_end {
  display: flex;
  justify-content: flex-end;
}

.ff_align_flex_end {
  align-items: flex-end;
}

.ff_flex_start {
  align-items: flex-start;
}

.ff_text_center {
  text-align: center;
}

.ff_text_end {
  text-align: end;
}

.ff_tar {
  text-align: end !important;
}

.ff_page_header {
  margin: 12px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  // align-items: center;
  .ff_back_arrow {
    margin-right: 8px;
    cursor: pointer;
    max-height: 20px;
    max-width: 20px;
  }

  .ff_heading {
    font-family: $base-font-bold;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: $font-color;
    align-items: center;
    display: flex;
  }

  .ff_refresh {
    img {
      margin-left: 10px;
      max-height: 16px;
    }
  }
}

.ff_counters {
  display: flex;
  margin: 8px 0 6px 24px;
  text-align: end;

  .ff_counter {
    margin-right: 12px;
    display: flex;
    align-items: baseline;
    border-right: 1px solid $border_light_gray;

    p {
      margin: 0;
    }

    .ff_count {
      font-family: lato-bold;
      font-weight: 900;
      font-size: 28px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $font_color_secondary;
    }

    .ff_status {
      font-family: lato-regular;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: $font-color;
      opacity: 0.7;
      margin: 0 10px 0 5px;
    }
  }

  .ff_no_border {
    border: hidden;
  }
}

// tables

.ff_hover_row {
  cursor: pointer;

  &:hover {
    background: var(--color-hover-over-blue) !important;
  }
}

.ff_100_table {
  width: 100%;
}

.ff_80_table {
  width: 80%;
}

.ff_70_table {
  width: 70%;
}

.ff_60_table {
  width: 60%;
}

.ff_50_table {
  width: 50%;
}

.ff_45_table {
  width: 45%;
}

.ff_40_table {
  width: 40%;
}

.ff_20_table {
  width: 20%;
}

// ff_div_table

.ff_div_table {
  border-collapse: collapse;
}

.ff_div_table thead tr th {
  padding: 5px 10px 5px 10px;
}

.ff_div_table thead tr th:first-child {
  padding-left: 15px;
}

.ff_div_table tbody tr td {
  padding: 5px 10px 5px 10px;
}

.ff_div_table tbody tr td:first-child {
  padding-left: 15px;
}

.ff_div_table thead tr {
  height: 32px;
  background-color: $table_header_background;
  border: 1px solid $border_light_gray;
}

.ff_div_table tbody tr {
  height: 32px;
  background-color: $table_row_background;
  border: 1px solid $border_light_gray;
}

.ff_div_table tbody tr:first-child {
  border-top: hidden;
}

.ff_div_table tbody tr:last-child {
  border-bottom: hidden;
}

.ff_div_table thead tr th {
  color: $font_color_secondary;
  font-family: lato-bold;
  font-style: normal;
  font-size: 13px;
}

.ff_div_table tbody tr td {
  color: $font_color_secondary;
  font-family: lato-regular;
  font-weight: normal;
  font-size: 13px;
}

.ff_mat_table {
  border-collapse: separate !important;
  border-left: 1px solid $border_light_gray!important;
  border-right: 1px solid $border_light_gray!important;
}

.ff_mat_table tbody tr:last-child td {
  border-bottom: hidden;
}

.ff_border_bottom_row tbody tr:last-child {
  border-bottom: 1px solid $border_light_gray !important;
}

.ff_table_header_top_align thead tr th {
  vertical-align: baseline;
  padding-top: 7px;
}

.ff_header_top_align thead tr th {
  vertical-align: top;
  padding-top: 7px;
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 5px !important;
}

.ff_single_div_table tbody tr:first-child {
  border-top: 1px solid $border_light_gray !important;
}

.ff_single_div_table tbody tr:last-child {
  border-bottom: 1px solid $border_light_gray !important;
}

.ff_border_bottom tbody tr:last-child td {
  border-bottom: 1px solid $border_light_gray !important;
}

// ff_div_table over //

// buttons
.ff_header_button {
  @include ff_button_32(primary, $width: 0);

  &:hover {
    @include ff_button_hover(primary);
  }

  &:disabled {
    @include ff_button_disable(primary);
  }
}

.ff_button_32 {
  @include ff_button_32(primary, $width: 0);

  &:hover {
    @include ff_button_hover(primary);
  }

  &:disabled {
    @include ff_button_disable(primary);
  }
}

.ff_button_32-80 {
  @include ff_button_32(primary, $width: 0);

  &:hover {
    @include ff_button_hover(primary);
  }

  &:disabled {
    @include ff_button_disable(primary);
  }

  display: unset;
  min-width: 80px;
}

.ff_button_32-130 {
  @include ff_button_32(primary, $width: 0);

  &:hover {
    @include ff_button_hover(primary);
  }

  &:disabled {
    @include ff_button_disable(primary);
  }

  display: unset;
  min-width: 130px;
}

.ff_button_32_secondary {
  @include ff_button_32(secondary, $width: 0);

  &:hover {
    @include ff_button_hover(secondary);
  }

  &:disabled {
    @include ff_button_disable(secondary);
  }

  img {
    height: max-content;
    max-height: 16px;
    max-width: 20px;
    margin-right: 7px;
  }
}

.ff_button_32-80_secondary {
  @include ff_button_32(secondary, $width: 0);

  &:hover {
    @include ff_button_hover(secondary);
  }

  &:disabled {
    @include ff_button_disable(secondary);
  }

  img {
    height: max-content;
    max-height: 16px;
    max-width: 20px;
    margin-right: 7px;
  }

  display: unset;
  min-width: 80px;
}

.ff_button_32-130_secondary {
  @include ff_button_32(secondary, $width: 0);

  &:hover {
    @include ff_button_hover(secondary);
  }

  &:disabled {
    @include ff_button_disable(secondary);
  }

  img {
    height: max-content;
    max-height: 16px;
    max-width: 20px;
    margin-right: 7px;
  }

  display: unset;
  min-width: 130px;
}

.ff_button_transparent {
  @include ff_button_28(primary);

  &:hover {
    border: hidden;
    background: rgba($color: #a4acb2, $alpha: 0.2);
    padding: 5px;
  }

  &:disabled {
    @include ff_button_disable(secondary);
    border: hidden;
    background: transparent;
    color: rgba($color: #87a5c1, $alpha: 1);
  }

  border: hidden;
  background: transparent;
  color: $secondary-button-font-color;
  padding: 5px;
  font-size: 14px;

  img {
    height: max-content;
    max-height: 18px;
    max-width: 20px;
    margin-right: 5px;
  }
}

.ff_button_32_transparent {
  @include ff_button_transparent(32);
}

.ff_button_28_transparent {
  @include ff_button_transparent(28);
}

.ff_button_32_transparent.ff_img-button,
.ff_button_28_transparent.ff_img-button {
  padding: 5px!important;
  img{
    margin-right: 0;
    max-height: 16px;
    max-width: 16px;
  }
}

.ff_button_32_transparent.ff_drop-img-button,
.ff_button_28_transparent.ff_drop-img-button {
  img{
    margin-right: 0;
    max-height: 16px;
    max-width: 16px;
  }
  background-color: var(--color-gray-f5);
  &:hover{
    background-color: var(--color-hover-over-blue);
  }
}

.ff_button_25_transparent {
  @include ff_button_transparent(28);
}

.ff_button_28 {
  @include ff_button_28(primary);

  &:hover {
    @include ff_button_hover(primary);
  }

  &:disabled {
    @include ff_button_disable(primary);
  }
}

.ff_button_28_secondary {
  @include ff_button_28(secondary);

  &:hover {
    @include ff_button_hover(secondary);
  }

  &:disabled {
    @include ff_button_disable(secondary);
  }
}

.ff_header_button_secondary {
  @include ff_button_32(secondary, $width: 0);

  &:hover {
    @include ff_button_hover(secondary);
  }

  &:disabled {
    @include ff_button_disable(secondary);
  }
}

.ff_button_32_contrast {
  @include ff_button_32(contrast, $width: 0);

  &:hover {
    @include ff_button_hover(contrast);
  }

  &:disabled {
    @include ff_button_disable(contrast);
  }

  img {
    height: max-content;
    max-height: 16px;
    max-width: 20px;
    margin-right: 7px;
  }
}

// custom margins

.ff_topM_5 {
  margin-top: 5px;
}

.ff_topM_10 {
  margin-top: 10px;
}

.ff_topM_12 {
  margin-top: 12px;
}

.ff_topM_14 {
  margin-top: 14px;
}

.ff_topM_24 {
  margin-top: 24px;
}

.ff_topM_36 {
  margin-top: 36px;
}

.ff_leftM_4 {
  margin-left: 4px;
}

.ff_leftM_5 {
  margin-left: 5px;
}

.ff_leftM_6 {
  margin-left: 6px;
}

.ff_leftM_12 {
  margin-left: 12px;
}

.ff_rightM_6 {
  margin-right: 6px;
}

.ff_rightM_12 {
  margin-right: 12px;
}

.ff_leftM_24 {
  margin-left: 24px;
}

.ff_rightM_24 {
  margin-right: 24px;
}

.ff_rightM_36 {
  margin-right: 36px;
}

.ff_rightM_48 {
  margin-right: 48px;
}

.ff_rightP_24 {
  padding-right: 24px;
}

.ff_leftP_12 {
  padding-left: 12px;
}

.ff_leftP_24 {
  padding-left: 24px;
}

.ff_max_12_width {
  max-width: 12px !important;
}

.ff_bottomP_16 {
  padding-bottom: 16px;
}

// width
.ff_min_width_120 {
  min-width: 120px;
}

// tabs
.ff_header_tabs {
  .ff_tab {
    padding-left: 24px;
    list-style: none;
    // height: 34px;
    height: fit-content;
    margin-bottom: 0px;

    .li_first {
      margin-left: 15px;
    }

    li {
      display: inline-block;
      padding: 0;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        background-color: #fff;
        border-bottom: 3px solid $link-color;
        padding: 0;

        a {
          font-weight: bold;
          color: $font_color_secondary !important;
        }
      }

      a {
        text-decoration: none;
        font-family: lato-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 32px;
        letter-spacing: 0.5px;
        color: $link-color;
      }
    }

    li.active {
      background-color: #fff;
      border-bottom: 3px solid $link-color;
      padding: 0;

      a {
        font-family: lato-bold;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: $font_color_secondary;
      }
    }
  }
}

.checkbox-inline-users-admin .mat-mdc-checkbox .mdc-form-field label {
  background: #f9f9f9!important;
}

// tabs 2
.ff_header_tabs_2 {
  .ff_tab {
    width: fit-content;
    list-style: none;
    height: fit-content;
    border: 1px solid $border_light_gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: hidden;
    padding: 3px 0px;
    margin: 0px;
    line-height: initial;
    background: #f9f9f9;

    li {
      display: inline-flex;
      padding: 0;
      cursor: pointer;
      border-left: 1px solid $border_light_gray;
      padding: 0 3px;
      border-radius: 0px;
      line-height: initial;

      &:hover {
        a {
          background: var(--color-hover-over-blue);
          color: #3e3e3e !important;
          border-radius: 3px;
        }
      }

      a {
        text-decoration: none;
        font-family: lato-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        padding: 7px 10px;
        letter-spacing: 0.5px;
        color: #2c2c2c;
      }
    }

    li.active {
      cursor: unset;
      a {
        font-family: lato-regular;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.5px;
        color: #ffffff;
        background-color: #17709c;
        border-radius: 3px;
      }

      &:hover {
        a {
          color: #ffffff !important;
          background-color: #17709c;
        }
      }
    }

    .li_first {
      border-left: hidden;
    }
  }
}

.ff_hide_input_arrow {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.ff_silent_input {
  outline: none;
  border: 1px solid $border_light_gray !important;
  border-radius: 4px !important;
  width: 80px !important;
  padding: 5px !important;

  &:focus {
    border: 1px solid #a4acb2 !important;
  }
}

.ff_down_link {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #17709c;
}

.ff_link {
  color: $link-color;
  cursor: pointer;
}

.ff_rightSideDialogBoxWindow {
  position: absolute!important;
  right: 10px;
  top: 1%;
  height: 98%;
}

// dialog box
.ff_dialogBoxWindow .mat-mdc-dialog-container {
  padding: 0;
}

.ff_dialog_box {
  .ff_header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ff_title {
      font-family: $base-font-bold;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: $font-color;
    }

    .ff_close_icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }

  .ff_content {
    padding: 0 20px 20px 20px;
  }

  .ff_footer {
    padding: 20px;
    width: 100%;
    display: flex;
    border-top: 1px solid $border_light_gray;

    .ff_action_buttons {
      width: fit-content;
      margin-left: auto;
      display: flex;
    }
  }

  &.right-panel{
    .ff_footer {
      position: absolute;
      bottom: 0;
    }
  }
}

.ff_key_value {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  .ff_key {
    margin: 0;
    color: #00314f;
  }

  .ff_value {
    margin: 0;
    color: #828282;
  }
}

.ff_error_outline {
  border: 1px solid #eb5757 !important;
}

.ff_custom_filters {

  .filter-width {
    width: 200px;
  }

  .relative {
    position: relative;
  }

  .last-filter {
    position: absolute;
    left: -38px;
  }
}

.ff-action-mat-menu {
  input[type="file"] {
    display: none;
  }

  label {
    cursor: pointer;
    font-weight: normal;
  }
}

.ff-time-remain {
  display: flex;
  align-items: baseline;
  height: fit-content;
  width: fit-content;
  padding: 2px 5px;
  border-radius: 10px;

  p {
    margin: 0;
  }

  .type {
    margin-right: 4px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #6d6d6d;
  }

  .time {
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  &.END_TIME {
    background-color: rgba($color: #eb5757, $alpha: 0.05);

    .time {
      color: #eb5757;
    }
  }

  &.START_TIME {
    background-color: rgba($color: #2196f3, $alpha: 0.05);

    .time {
      color: #2196f3;
    }
  }
}

.ff-mat-search {
  max-height: 345px !important;
  max-width: 220px !important;
  margin-top: 34px;
  margin-left: 16px;
}

.attachment-wrapper {
  position: relative;

  file-upload {
    border: hidden !important;
    width: fit-content;
    box-shadow: unset;
    background-color: transparent;
  }

  .upload-button {
    text-align: start !important;
    border-left: none !important;
    background: none !important;
    white-space: nowrap;

    &:hover {
      color: #000 !important;
    }
  }

  .upload-text {
    display: flex !important;
    padding: 0 !important;
    width: unset !important;
    height: 18px !important;
  }

  .upload-wrapper {
    display: flex;
    align-items: end;

    .up-text {
      margin: 0 0 0 6px;
      font-size: 14px;
      color: #17709c;
    }

    .attachment-logo {
      height: 16px;
    }
  }

  .file {
    margin: auto 0;
    display: flex;
    align-items: center;

    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      font-family: lato-regular;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #17709c;
      cursor: pointer;
      margin-left: 6px;
    }

    .doc_image {
      height: 16px;
      cursor: pointer;
    }

    .cross {
      height: 12px;
      margin-left: 6px;
    }
  }

  .hide-upload-button {
    .upload-button {
      display: none;
    }
  }
}

.ff_display_none {
  display: none;
}

.ff-no-data {
  text-align: center;
  color: $infocused-text !important;
}

.ff-form-theme {
  .readonly-text {
    color: #565656 !important;
  }

  .readonlyInput {
    width: -webkit-fill-available;
    color: #565656 !important;
    background-color: #f9f9f9;
    border: 1px solid $border_light_gray;
    padding: 0 5px;
    height: 30px;
    border-radius: 5px;
    font-size: 13px;

    &:focus {
      outline: none;
    }
  }

  .ff-label {
    margin-bottom: 6px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #00314F;

    .req {
      color: #EB5757;
    }
  }

  .ff-fl-field {
    width: 100%;
    padding-bottom: 16px;
  }

  .ff-xsm-field {
    width: 100px;
    padding-bottom: 16px;
  }
  .ff-xxs-field {
    width: 150px;
    padding-bottom: 16px;
  }

  .ff-sm-field {
    width: 200px!important;
    padding-bottom: 16px;
  }

  .ff-md-field {
    width: 300px;
    padding-bottom: 16px;
  }

  .ff-lg-field {
    width: 448px;
    padding-bottom: 16px;
  }

  .ff-xlg-field {
    width: 696px;
    padding-bottom: 16px;
  }

  .display-block {
    display: block;
  }
}

.ff-page-contents {
  margin-left: 64px;
  background-color: #ffffff;
}

.mapboxgl-ctrl-bottom-left {
  bottom: 0;
  left: 0;
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  right: 0;
  bottom: 0;
  display: none;
}

.ff-bid-row-background {
  background: #f3f6f9 !important;
}

.ff_header_tabs_bg {
  .ff_tab {
    text-decoration: none;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #17709C;
    background-color: #d1e5f5;
    padding: 4px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: fit-content;
  }

  .ff_tab li {
    border-radius: 5px;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    padding: 0 12px;
  }

  .ff_tab li.active {
    border-radius: 5px;
    background-color: #fff;
    padding: 0 12px;
  }

  li a {
    color: #3e3e3e;
  }
}

.mapboxgl-popup-close-button{
  font-size: 20px;
}

.ulip-dashboard-logo{
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  img{
    width: 220px;
  }
}

.gmap-marker-label {
  bottom: -20px;
  left: 0px;
  position: relative;
  font-size: 11px !important;
  color: #ffffff !important;
  background: #747474eb;
  padding: 2px 4px;
  border-radius: 5px;
}

.gmap-toll-index-label {
  font-size: 11px!important;
  font-family: lato-bold!important;
  color: #565656!important;
}

.gmap-marker-label-index {
  bottom: 6px;
  left: 0px;
  position: relative;
  font-size: 13px!important;
  font-family: lato-bold!important;
  color: #000000!important;
  background: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.gmap-marker-geo-count {
  bottom: 30px;
  left: 9px;
  position: relative;
  font-size: 0px !important;
  line-height: 18px;
  font-family: lato-bold !important;
  background: var(--color-orange);
  border: 1px solid #fff;
  width: 12px;
  height: 12px;
  color: #fff !important;
  border-radius: 50%;
}

.gmap-toll-label {
  bottom: -20px;
  left: 0px;
  position: relative;
  font-size: 10px!important;
  font-family: lato-regular!important;
  color: #6d6d6d!important;
  background: #fcfcfc;
  padding: 0px 4px;
  border-radius: 5px;
}

.gmap-marker-pulse{
  background: #2196F3;
	border-radius: 50%;
	margin: 12px;
	height: 24px;
	width: 24px;

  box-shadow: 0 0 0 0 #2196F3;
	transform: scale(1.2);
	animation: gmapPulse 2s infinite;
}

@keyframes gmapPulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($color: #2196F3, $alpha: 0.9);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba($color: #2196F3, $alpha: 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($color: #2196F3, $alpha: 0);
	}
}

$small-stop-pulse: #fbd754;
$large-stop-pulse: #EB5757;
$green-pulse: #219653;

.gmap-marker-stop-pulse{
  background: $small-stop-pulse;
	border-radius: 50%;
	margin: 5px;
	height: 10px;
	width: 10px;
  box-shadow: 0 0 0 3px rgba($color: $large-stop-pulse, $alpha: 0.3);
  color: #ffffff!important;
  line-height: 16px!important;
  font-size: 12px!important;
  &.large-stop{
    background: $large-stop-pulse;
    box-shadow: 0 0 0 3px rgba($color: $large-stop-pulse, $alpha: 0.3);
  }
}

.gmap-marker-stop-pulse.gmaps-small-pulse{
  height: 7px;
	width: 7px;
}

.gmap-marker-ping-pulse{
  background: $green-pulse;
	border-radius: 50%;
	margin: 5px;
	height: 10px;
	width: 10px;
  box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.6);
  color: #ffffff!important;
  line-height: 16px!important;
  font-size: 12px!important;
}

.gmap-marker-ping-pulse.gmaps-small-pulse{
  height: 7px;
	width: 7px;
}

.gmap-vehicle-label{
  bottom: -34px;
  left: 30px;
  position: relative;
  font-size: 11px!important;
  font-family: 'lato-bold'!important;
  color: #6d6d6d!important;
  background: #fcfcfc;
  padding: 0px 2px;
  border-radius: 5px;
}

.inter-filter{
  position: relative;
  .filter_component{
    position: relative!important;
  }
}

.ff-chip-auto-complete {
  margin-top: 9px !important;
}

// loader
.ff-two-dot-loader {
  width: 80px;
  height: 80px;
  animation: anim-rotate 1.6s infinite ease;
  background: rgba($color: #ffffff, $alpha: 0);

  &:before,
  &:after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
      border-radius: 12px;
      opacity: .9;
  }

  &::before {
      background: #F18C0C;
      transform: translate(-1.5em, -1.5em) scale(1);
      box-shadow: 3em 3em 0 #F18C0C;
      animation: anim-1-2 1.6s infinite ease;
  }

  &:after {
      background: #2196F3;
      // background: #f963a3;
      transform: translate(1.5em, -1.5em) scale(1);
      box-shadow: -3em 3em 0 #2196F3;
      animation: anim-3-4 1.6s infinite ease;
  }
}

@keyframes anim-1-2 {
  50% {
      transform: scale(1.3) translate(0, 0);
      box-shadow: 0 0 0 #F18C0C;
      opacity: .9;
  }
}

@keyframes anim-3-4 {
  50% {
      transform: scale(1.3) translate(0, 0);
      box-shadow: 0 0 0 #2196F3;
      opacity: .9;
  }
}

@keyframes anim-rotate {
  0% {
      transform: rotate(0deg);
  }

  50% {
      transform: rotate(360deg);
  }

  100% {
      transform: rotate(720deg);
  }
}

.ff_mat_menu{
  img{
    height: 14px;
    margin-left: 32px;
  }
  img.small{
    height: 12px;
    margin-left: 32px;
  }
}

.ff_popup-list-wrapper.down-arrow{
  ::after {
      content: " ";
      position: absolute;
      left: 15px;
      bottom: -6px;
      border: 1px solid rgba(74, 74, 74, 0.2);
      border-width: 1px 1px 0 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      background: #fff;
  }
}

.ff_popup-list-wrapper.up-arrow{
  ::after {
      content: " ";
      position: absolute;
      left: 15px;
      top: -6px;
      border: 1px solid rgba(74, 74, 74, 0.2);
      border-width: 0 0 1px 1px;
      display: inline-block;
      padding: 5px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      background: #fff;
  }
}

.ff_popup-list-wrapper{
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(74, 74, 74, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1000;

  .details-wrapper{
      padding: 8px 0;
      height: fit-content;
      position: absolute;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(74, 74, 74, 0.2);
      min-width: 200px;

      .header{
          display: flex;
          justify-content: space-between;
          padding: 0 12px;
          align-items: center;
          margin-bottom: 5px;

          p{
              color: #2b3c49;
              margin: 0 6px 0 0;
              font-size: 15px;
              font-family: lato-bold;
              white-space: nowrap;
          }

          img{
              width: 12px;
              cursor: pointer;
          }

      }

      .content{
          padding: 0 12px;
          max-height: 200px;
          overflow: overlay;

          .row-container {
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              margin-bottom: 3px;

              .column1{
                  display: flex;
                  align-items: baseline;

                  .sno{
                      margin: 0 8px 0 0;
                  }
  
                  .entity-name {
                      margin: 0;
                      font-size: 14px;
                      line-height: 18px;
                      color: #3e3e3e;
                  }
              }
  
              .status {
                  padding: 0 5px;
                  margin: 0 0 0 10px;
                  font-size: 11px;
                  height: fit-content;
                  border-radius: 3px;

                  &.undelivered {
                      color: #EB5757;
                      background-color: rgba($color: #EB5757, $alpha: 0.1);
                  }
  
                  &.delivered {
                      color: #219653;
                      background-color: rgba($color: #219653, $alpha: 0.1);
                  }
              }
          }
      }
  } 
}

.ff_button_28_transparent.ff-hover-effect{
    padding: 3px;
    margin: 0 auto;

    .size-12 {
        width: 12px;
        height: 12px;
        margin-right: 0;
    }
    .size-14 {
        width: 14px;
        height: 14px;
        margin-right: 0;
    }
}

.right{
  text-align: right!important;
}

.ff_dialog_box_loaderContent{
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  .loader-wrapper{
      background-color: #fff;
      border-radius: 5px;
      width: 300px;
      height: fit-content;
      padding: 24px;
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .loader{
          display: flex;
          justify-content: center;
      }
      p{
          margin: 10px 0 0 0;
          text-align: center;
          font-family: lato-regular;
          font-size: 15px;
          line-height: 15px;
          color: #565656;
      }
  }
}

.ff_filter-image-black{
  filter: brightness(0);
}

.ff_filter-image-white{
  filter: brightness(0) invert(1);
}

.ff_filter-image-green{
  filter: invert(39%) sepia(98%) saturate(335%) hue-rotate(93deg) brightness(99%) contrast(94%);
}

.ff_filter-image-orange{
  filter: invert(66%) sepia(51%) saturate(4888%) hue-rotate(2deg) brightness(104%) contrast(91%);
}

.ff_filter-image-clickable-blue{
  filter: invert(48%) sepia(69%) saturate(2794%) hue-rotate(185deg) brightness(99%) contrast(92%);
}

.ff_filter-image-blue{
  filter: invert(15%) sepia(17%) saturate(5261%) hue-rotate(174deg) brightness(97%) contrast(101%);
  &.with-shadow{
    filter: invert(15%) sepia(17%) saturate(5261%) hue-rotate(174deg) brightness(97%) contrast(101%) drop-shadow( 0px 1px 1px rgba(0, 49, 79, .2));
  }
}

.ff_filter-image-red{
  filter: invert(76%) sepia(50%) saturate(5882%) hue-rotate(319deg) brightness(87%) contrast(114%);
}

.ff_filter-image-light-gray{
  filter: invert(41%) sepia(5%) saturate(5%) hue-rotate(142deg) brightness(99%) contrast(80%);
}

.ff_table-icon{
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}

.ff_grey-head {
  display: flex;
  align-items: center;
  background: rgba(102, 140, 175, 0.1);
  margin: 0px -26px;
  p {
      margin: 0;
      padding: 8px 26px;
      font-family: lato-bold;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #2b3c49;
  }
  .side_info {
      font-family: lato-regular;
      margin: 0;
      padding: 0;
      margin-left: 12px;
      padding-left: 12px;
      font-size: 14px;
      font-weight: normal;
      border-left: 1px solid var(--border-gray);
      color: var(--color-red);
  }
}

.ff_loader-line {
  width: 200px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 10px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.ff_loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: coral;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }

  50% {
      left: 20%;
      width: 80%;
  }

  100% {
      left: 100%;
      width: 100%;
  }
}
