@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "assets/scss/mixins";
@import "assets/scss/variables";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;800&display=swap');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: lato-regular!important;
  overflow: hidden;
}

:root {
  --color-heading: #00314F;
  --color-link: #17709C;
  --color-hover-over-blue: #ecf6ff;
  --color-focused-blue: rgb(240 249 255);
  --color-orange: #F17922;
  --color-red: #EB5757;
  --color-green: #219653;

  --color-gray-3e: #3e3e3e;
  --color-gray-56: #565656;
  --color-gray-6d: #6d6d6d;
  --color-gray-bf: #bfbfbf;
  --color-section-background: #ebebeb;
  --color-gray-f5: #f5f5f5;
  --color-gray-f3: #f3f3f3;
  --color-gray-fc: #fcfcfc;
  --color-placeholder: #9faab6;
  --border-gray: #A4ACB2;

  --border-light-gray: rgba(67, 72, 92, 0.2);
  --border-highlight-blue: rgba(23, 112, 156, 0.6);
  --highlight-color: #F17922;
  --info-background: rgba(255,248,225,1.0);
  //mdc
  --mdc-typography-body1-font-family: 'lato-regular';
  --mdc-typography-body1-font-weight: 400;
  // shadow
  --tooltip-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

@font-face {
  font-family: lato-extrabold;
  src: url(assets/fonts/Lato-Black.ttf);
  font-weight: 800;
}

@font-face {
  font-family: lato-bold;
  src: url(assets/fonts/Lato-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: lato-semibold;
  src: url(assets/fonts/Lato-SemiBold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: lato-regular;
  src: url(assets/fonts/Lato-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: lato-Light;
  src: url(assets/fonts/Lato-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: lato-thin;
  src: url(assets/fonts/Lato-LightItalic.ttf);
  font-weight: 200;
}

// .mat-mdc styles

button {
  &:focus {
    outline: 0!important;
  }
}

.mdc-text-field{
  padding: 5px 10px!important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
  top: 13px;
  font-size: 14px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{
  top: 28px;
  font-size: 14px;
}

.mat-mdc-form-field-infix {
  min-height: 20px!important;
  line-height: 18px!important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color: var(--border-light-gray)!important;
}

.mat-form-field-invalid.ng-touched{
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, 
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, 
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
    border-color: var(--color-red)!important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
  border-color: var(--border-highlight-blue)!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--border-light-gray)!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--color-gray-56)!important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::after{
  border-bottom-color: var(--color-red)!important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--color-link)!important;
}

.mat-mdc-form-field,
.mdc-form-field{
  background: #fff;
  -webkit-font-smoothing: initial!important;
  letter-spacing: 0.1px!important;
}

.ff_form-filter {
  background-color: #fff;
  .mdc-text-field {
    padding: 4px 10px!important;
    color: var(--border-light-gray);
  }
  .mdc-text-field--focused{
    color: var(--border-highlight-blue);
  }
}

.ff_form-filter.ff_medium-width-filter{
  width: 180px;
  height: fit-content;
}

.mat-mdc-select-arrow-wrapper{
  height: 20px!important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0,0,0,.1)!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: var(--color-gray-f5)!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused{
  background-color: #ffffff!important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}

.mdc-text-field .mdc-text-field__input {
  caret-color: var(--color-link)!important;
}

.mat-mdc-select{
  line-height: 12px!important;
}

.mat-mdc-input-element[type="datetime-local"]{
  border: 1px solid var(--border-light-gray);
  border-radius: 3px;
  padding: 2px 5px;
}

.mat-mdc-input-element[type="time"]{
  border: 1px solid var(--border-light-gray);
  border-radius: 3px;
  padding: 2px 5px;
}

.mat-mdc-input-element::placeholder,
.mat-mdc-select-placeholder{
  -webkit-font-smoothing: initial;
  color: var(--color-placeholder);
  font-size: 13px!important;
  line-height: 16px!important;
}

input::placeholder {
  color: var(--color-placeholder)!important;
}

.mdc-text-field__input, 
.mat-mdc-select-value-text{
  color: #3e3e3e;
  font-size: 13px!important;
  line-height: 16px!important;
  -webkit-font-smoothing: initial;
  letter-spacing: 0.1px!important;
}

// mat-chip
.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
  line-height: 20px!important;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label{
  font-size: 13px!important;
  white-space: pre-line;
  line-height: 16px;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-top: 2px!important;
  margin-bottom: 2px!important;
  padding: 4px 0;
}

.mat-mdc-standard-chip {
  height: var(--mdc-chip-container-height, auto)!important;
}

.mat-mdc-chip.mat-mdc-standard-chip.error {
  background-color: rgba($color: #EB5757, $alpha: 0.2)!important;
  color: #3e3e3e!important;
}

// mat-chip-over

.mat-mdc-form-field.ng-touched{
  .mat-mdc-form-field-error,
  .mdc-text-field--invalid{
    color: var(--color-red);
    -webkit-font-smoothing: initial!important;
  }
}

.text-danger{
  color: var(--color-red);
  -webkit-font-smoothing: initial!important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
  padding: 0 6px!important;
}

.mat-mdc-snack-bar-container {
  top: 0;
  margin: 10px auto!important;
  position: absolute!important;
  height: fit-content;
  width: fit-content;
}

// --> radio button

.mat-mdc-radio-touch-target{
  height: 24px!important;
  width: 24px!important;
}

.mdc-form-field>label {
  line-height: 16px;
  padding-right: 10px!important;
}

.mat-mdc-radio-button .mdc-radio{
  padding: 0!important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: 0!important;
  left: 0!important;
  width: 16px!important;
  height: 16px!important;
}

.mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.7)!important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control{
  height: 20px!important; 
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-width: 1px!important;
}

.mat-mdc-radio-button .mdc-form-field label{
  font-family: lato-regular!important;
}

.mdc-radio__background{
  width: 16px!important;
  height: 16px!important;
}

.mdc-radio__inner-circle{
  border-width: 8px!important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle, 
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle{
  border-color: var(--color-link)!important;
}
// .mat-mdc styles over

.ff_mat-prefix-icon,
.ff_mat-suffix-icon {
  max-width: 14px;
  max-height: 14px;
}

// mat-menu
.mat-mdc-menu-item .mat-icon{
  margin-right: 12px;
}

.mat-mdc-menu-content,
.mat-mdc-autocomplete-panel {
  background-color: #fff;
  border: 1px solid var(--border-light-gray);
  border-radius: 3px;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.3);
  padding: 4px 0px !important;
  -webkit-font-smoothing: initial;
  letter-spacing: 0.1px!important;
  
  .mat-mdc-menu-item, 
  .mat-mdc-option{
    min-height: 26px!important;
    border-left: 2px solid var(--border-highlight-blue)!important;
    margin: 2px 0!important;

    .mdc-list-item__primary-text{
      font-size: 13px!important;
      line-height: 18px!important;
      -webkit-font-smoothing: initial!important;
      letter-spacing: 0.1px!important;
    }
  
    &:hover{
      background-color: var(--color-hover-over-blue)!important;
      border-left: 2px solid var(--color-link)!important;
    }
  }
  
    .matselectedMenu {
      background: #17709c !important;
      border-left: 2px solid var(--color-heading) !important;
  
      .mdc-list-item__primary-text {
        color: #fff !important;
      }
  
      &:hover {
        background-color: #17709c !important;
        border-left: 2px solid var(--color-link) !important;
      }
    }

}

// cdk
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end;
}
// cdk over

// mat-select
.mat-mdc-select-arrow{
  display: none;
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    .mat-mdc-select-panel {
      max-height: 300px!important;
      padding: 5px 0!important;
      box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
      background: #fff;
      .mat-mdc-option{
        padding: 4px 8px!important;
        min-height: 24px!important;
        .mdc-list-item__primary-text{
          font-family: lato-regular;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.2px;
          -webkit-font-smoothing: initial!important;
        }
        &:hover{
          background-color: var(--color-hover-over-blue)!important;
          .mdc-list-item__primary-text{
            color: var(--color-gray-3e)!important;
          }
        }
      }
      .mat-mdc-option-active{
        background-color: var(--color-link)!important;
        .mdc-list-item__primary-text{
          color: #fff!important;
        }
      }
      .mat-mdc-option .mat-pseudo-checkbox-minimal {
        display: none!important;
      }
    }
  }
}

.mat-mdc-optgroup-label {
  padding-left: 5px!important;
  min-height: 24px!important;
  background-color: var(--color-section-background)!important;
  font-family: lato-bold!important;
  font-size: 13px!important;
}

//  ngx-pagination
.ngx-pagination{
  padding-left: 0;
  margin-bottom: 0!important;
  display: flex;
}

.paginate-data{
  margin-left: auto;
}

.ngx-pagination .pagination-previous a::before, .ngx-pagination .pagination-previous.disabled::before {
  content: '<'!important;
  margin-right: 0!important;
}

.ngx-pagination .pagination-next a::after, .ngx-pagination .pagination-next.disabled::after {
  content: '>'!important;
  margin-left: 0!important;
}

.ngx-pagination .disabled {
  padding: 0 0.625rem!important;
}

.ngx-pagination .current {
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  background: var(--color-link)!important;
}

.ngx-pagination a, .ngx-pagination button {
  border-radius: 4px!important;
  font-size: 12px;
  line-height: 16px!important;
  color: var(--color-gray-3e)!important;
  &:hover{
    text-decoration: none;  
  }
  &:focus{
    outline: 0!important;
  }
}
//  ngx-pagination over

// checkbox
.mat-mdc-checkbox {
  margin-right: 0px;

  label {
    margin-bottom: 0px !important;
    line-height: 16px !important;
    color: var(--color-gray-3e);
    padding-left: 8px !important;
  }

  .mdc-form-field {
    align-items: flex-start !important;
    margin: 5px 0 !important;
  }

  .mdc-checkbox {
    width: 16px !important;
    height: 16px !important;
    flex: 0 0 16px !important;
  }

  .mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
    border-width: 1px !important;
  }

  .mdc-checkbox__checkmark {
    padding: 1px;
  }

  .mdc-checkbox__mixedmark {
    width: 80% !important;
  }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  border-color: var(--color-link) !important;
  background-color: var(--color-link) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: var(--color-link) !important;
  background-color: var(--color-link) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: var(--color-link) !important;
}

.mat-mdc-checkbox .mdc-form-field label{
  font-family: lato-regular!important;
  font-size: 13px;
}

.mat-mdc-checkbox .mdc-checkbox{
  padding: 0!important;
  margin: 0!important;
}

.mat-mdc-checkbox-touch-target {
  height: 16px!important;
  width: 16px!important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control{
  height: 16px!important;
  width: 16px!important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background{
  top: 0!important;
  left: 0!important;
}

.mat-mdc-checkbox.mat-accent{
  --mdc-checkbox-selected-checkmark-color: #fff!important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: var(--color-link)!important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
  background: var(--color-link)!important;
}

.mat-pseudo-checkbox-full{
  border: 1px solid var(--border-light-gray)!important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option-active {
  .mdc-list-item__primary-text{
    color: #fff!important;
  }
  .mat-pseudo-checkbox-full{
    border: 1px solid #fff!important;
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate{
    border: 1px solid #fff!important;
  }
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel{
  .mat-mdc-option.mdc-list-item--disabled{
    opacity: 1;
    background-color: #e2e2e2;
  }
}

.mdc-list-item--disabled .mdc-list-item__primary-text{
  color: #6d6d6d;
}

// checkbox over

// table 
.mat-mdc-table .mdc-data-table__header-row, 
.mat-mdc-table .mdc-data-table__row{
  height: 32px!important;
}

.mat-mdc-tooltip  {
  white-space: pre-line;
}

.mdc-data-table__cell, .mdc-data-table__header-cell{
  border-color: var(--border-light-gray);
  -webkit-font-smoothing: initial;
  letter-spacing: 0.1px!important;
}
// table over

// mat-select with search
.customSearchHeight {
  max-height: 345px !important;
}

.customSearch {
  height: 28px !important;
}

.mat-select-search-custom-header-content {
  position: absolute;
  top: 4px;
  left: 2px;

  .material-icons {
    font-size: 18px;
    color: var(--color-gray-56) !important;
  }
}

.mat-mdc-option.contains-mat-select-search.mat-select-search-no-entries-found {
  height: 7rem !important;
}

.mat-mdc-option.customeSearchOptions.mdc-list-item--selected {
  background-color: var(--color-link);
  color: #fff;
}

.mat-mdc-option.contains-mat-select-search {
  margin-bottom: 2px;
}

.mat-select-search-inner {
  top: 4px!important;
  left: 4px!important;
  width: 96% !important;
  border-bottom-style: none!important;

  .mat-select-search-input {
    padding: 0 20px !important;
    height: 24px !important;
    line-height: 24px !important;
    border: 1px solid var(--border-light-gray)!important;
    border-radius: 4px!important;
    outline: none!important;
  }

  .mat-divider{
    border-top-width: 0px!important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 24px!important;
    height: 24px!important;
    padding: 0!important;
    .mat-icon {
      font-size: 18px!important;
      line-height: 24px!important;
      color: #6d6d6d!important;
    }
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target{
    height: 24px!important;
    width: 24px!important;
  }
  
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
  top: 0px !important;
  right: -20px !important;

  .mat-icon {
    font-size: 18px !important;
    color: var(--color-gray-56);
  }
}

.mat-select-search-no-entries-found {
  padding: 0 0 6px !important;
}

// mat-select with search

// date-picker -toggel
.mat-datepicker-toggle{
  
  .mat-mdc-button-touch-target{
    top: unset!important;
    left: unset!important;
    height: 16px!important;
    width: 16px!important;
  }
  
  .mat-mdc-icon-button{
    width: 16px!important;
    height: 16px!important;
    padding: 0!important;
  }
  
  .mat-mdc-icon-button svg, .mat-mdc-icon-button img{
      width: 16px!important;
      height: 16px!important;
  }

}

// date range 

.mat-calendar-body-selected{
  background-color: var(--color-link)!important;
}
.mat-calendar-body-in-range{
  &::before{
    background-color: var(--border-highlight-blue)!important;
  }
  .mat-calendar-body-cell.mat-focus-indicator{
    color: #fff!important;
  }
}
.mat-calendar-body-preview-end .mat-calendar-body-cell-content.mat-focus-indicator {
  background-color: var(--color-link)!important;
  color: #fff!important;
}

.mat-calendar-body-cell.mat-calendar-body-disabled:hover{
  .mat-calendar-body-cell-content.mat-focus-indicator{
    background-color: var(--color-gray-bf)!important;
    cursor: not-allowed;
  }
}

.mat-calendar-body-cell:hover{
  .mat-calendar-body-cell-content.mat-focus-indicator{
    background-color: var(--color-link)!important;
    color: #fff!important;
  }
}

.mat-calendar-body-active .mat-focus-indicator{
  background-color: var(--color-link)!important;
  color: #fff!important;
}

// date range

.mat-mdc-form-field-infix .mat-date-range-input .mat-date-range-input-wrapper{
  font-size: 13px;
}
.custom-daterangepicker{
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
    height: 0!important;
    padding: 0!important;
  }
}

// mat-slider
.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: var(--color-link)!important;
  opacity: .24;
}

.mat-mdc-slider .mdc-slider__track--active_fill{
  border-color: var(--color-link)!important;
}

.mdc-slider .mdc-slider__thumb {
  left: 0!important;
  height: 24px!important;
  width: 0px!important;
}

.mat-mdc-slider{
  margin-left: 0!important;
  margin-right: 0!important;
}

.mdc-slider{
  height: 24px!important;
  margin: 0!important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color:var(--color-link)!important;
  border-color: var(--color-link)!important;
}

.mat-mdc-menu-content .mat-mdc-menu-item {
  .ff_mat-menu-icon{
    max-width: 16px;
    max-height: 16px;
    margin-right: 12px;
  }
}

.ff_pointer-event-none{
  pointer-events: none;
}

.mat-mdc-tooltip {
  font-family: lato-regular;
  font-size: 13px;
  padding: 0;
  background-color: #fff;
  border: 1px solid rgba($color: #A4ACB2, $alpha: 0.4);
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  max-width: 350px;
}

.mdc-tooltip__surface{
  color: var(--color-gray-6d)!important;
  background-color: #fff!important;
  max-width: -webkit-fill-available!important;
}

// mat-mdc-styles over

.display-flex {
  display: flex;
}

.top-margin-25 {
  margin-top: 25px;
}

.bottom-margin-25 {
  margin-bottom: 25px;
}

.top-margin-0 {
  margin-top: 0;
}

.top-margin-58 {
  margin-top: 58px;
}

.top-margin-20 {
  margin-top: 20px;
}

.bottom-margin-20 {
  margin-bottom: 20px !important;
}

.top-margin-24 {
  margin-top: 24px;
}

.bottom-margin-24 {
  margin-bottom: 24px;
}

.top-margin-10 {
  margin-top: 10px;
}

.bottom-margin-10 {
  margin-bottom: 10px;
}

.top-margin-12 {
  margin-top: 12px;
}

.bottom-margin-12 {
  margin-bottom: 12px;
}

.top-margin-16 {
  margin-top: 16px;
}

.bottom-margin-16 {
  margin-bottom: 16px;
}

.top-margin-5 {
  margin-top: 5px;
}

.bottom-margin-5 {
  margin-bottom: 5px;
}

.top-margin-8 {
  margin-top: 8px;
}

.bottom-margin-8 {
  margin-bottom: 8px;
}

.top-margin {
  margin-top: 50px !important;
}

.top-margin-lg {
  margin-top: 80px !important;
}

.top-margin-sm {
  margin-top: 30px !important;
}

.bottom-margin-sm {
  margin-bottom: 30px !important;
}

.bottom-margin-lg {
  margin-bottom: 80px !important;
}

.bottom-margin {
  margin-bottom: 50px !important;
}

.center-img {
  display: initial;
}

.full-width {
  width: 100%;
}

::-webkit-scrollbar {
  background: rgba(164, 172, 178, .2);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #a4acb2;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.file-upload-data {
  .upload-button {
    text-align: end !important;
    border-left: none !important;
    background: none !important;
    white-space: nowrap;
    position: absolute !important;
    right: 0;

    &:hover {
      color: #000 !important;
    }
  }

  .upload-text {
    border-radius: 4px !important;
    width: auto !important;
  }
}

.no-padding {
  padding: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.text-end {
  text-align: end;
}

.ellipsis {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-logo-size {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0px;
  left: 5px;
  padding: 5px;
}

.center {
  text-align: center !important;
}

.red-color {
  color: #EB5757;
}

.page-content {
  margin-left: 64px;
  background: #fff;
}

.head-nav-text {
  padding-left: 24px;

  .head-primary-text {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    font-family: lato-bold;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    padding-right: 22px;
    margin-right: 24px;
    color: #00314F;
  }

  .live-head-text {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;

    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-family: lato-bold;
    color: #4F4F4F;
  }
}

.head-actions {
  padding-right: 30px;
  margin-bottom: 20px;
  margin-top: 20px;

  .create-bid-btn {
    background: #17709C;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    margin-right: 30px;
    font-family: lato-regular;
    padding: 12px 20px 14px 20px;
    text-align: center;
    color: #FFFFFF;
  }
}

.transporter-details-accordain {
  .mat-expansion-panel-header-title {
    font-family: lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #2B3C49;
  }

  .mat-expansion-panel-header {
    background: rgba(102, 140, 175, 0.1);
    padding: 9px 24px 10px 24px;
    height: fit-content !important;
  }

  .mat-expansion-panel-header.mat-expanded:hover {
    background: rgba(102, 140, 175, .1) !important;
  }

  .mat-expansion-panel-header.mat-expanded:focus {
    background: rgba(102, 140, 175, .1) !important;
  }

  .mat-expansion-panel {
    margin-bottom: 20px;
    box-shadow: none !important;
    border: 1px solid rgba(43, 60, 73, .1);
    box-sizing: border-box;
  }

  .mat-expansion-panel-body {
    padding: 20px 24px 16px 26px;
  }

  .accordain-data {
    .heading-data {
      font-family: lato-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #00314F;
    }

    .value {
      font-family: lato-regular;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      color: #828282;
    }

    p {
      margin: 0 0 5px;
    }
  }
}

.dialog-form {
  h3 {
    font-family: lato-bold;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    color: #00314F;
    margin-top: 0px;
  }
}

.top-margin-head {
  margin-top: 16px;
}

.addAlertDailog {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.revokeIndent {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.confirmVehicle {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.initIndent {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.tripCompletion {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.modifyResponse {
  position: relative !important;

  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.submitIndent {
  position: relative !important;

  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
    padding-top: 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.noshowVehicle {
  position: relative !important;

  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.submitIndent {
  position: relative !important;

  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 20px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.confirmIndent {
  position: relative !important;

  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .custom-form {
    padding: 24px;
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
    padding-top: 24px;
    text-align: end;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.uploadEpod {
  .mat-mdc-dialog-container {
    padding: 0px;
    overflow: hidden;
    max-height: calc(100vmin - 70px);
  }

  .action-btn {
    border-top: 1px solid rgba(102, 140, 175, 0.2);
    padding: 0px 24px;
  }

  .dailog-close-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 17px;
  }
}

.modal-cancel-btn {
  font-family: lato-bold !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 7px 38px 9px 38px !important;
  background: #FFFFFF !important;
  border: 1px solid #17709C !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: #17709C !important;

}

.modal-submit-btn {
  font-family: lato-bold;
  font-style: normal;
  line-height: 18px;
  color: #FFFFFF;
  border: 1px solid #17709C;
  background-color: #17709C;
  padding: 8px 38px 10px 38px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  line-height: 18px !important;

  :hover {
    background-color: #17709C !important;
    color: #fff !important;
  }
}

.bottom-margin-25 {
  margin-bottom: 25px;
}

.capatalize {
  text-transform: capitalize;
}

.margin-auto {
  margin: auto;
}

//CSS loader Classes
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 100px auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #17709C;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #17709C transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//CSS loader classes Over

.overlap-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

//Bottom Border for table headings
.my-table {
  th {
    box-shadow: 0 1px rgb(212, 212, 212);
  }
}

.ff_stepper {
  .mat-step-header {
    height: 24px !important;
  }

  .mat-step-icon {
    height: 18px !important;
    width: 18px !important;
  }

  .mat-step-icon .mat-icon {
    font-size: 13px !important;
    height: 13px !important;
    width: 13px !important;
  }

  .mat-horizontal-stepper-header {
    padding: 0 !important;
    min-width: fit-content!important;
  }

  .mat-stepper-horizontal-line {
    margin: 0 16px!important;
    max-width: 32px!important;
  }

  .mat-horizontal-content-container {
    padding: 0!important;
  }
  
  .mat-step-text-label {
    font-size: 13px!important;
    line-height: 13px!important;
  }
  
  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #6d6d6d!important;
    font-family: lato-regular!important;
  }

  .mat-step-header .mat-step-label.mat-step-label-active.mat-step-label-selected {
    color: #3e3e3e!important;
    font-family: lato-bold!important;
  }

}

.ff_higlighted-stepper-row .mat-horizontal-stepper-header-container{
  background: #f3f3f3;
  padding: 2px 5px;
  border-radius: 5px;
  overflow: auto;
}

.mat-horizontal-stepper-header{
  height: 42px!important;
}

.mat-step-text-label{
  font-size: 14px!important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
  background-color: var(--color-link)!important;
}

.table-width-80 {
  width: 80%;
}

.table-width-100 {
  width: 100%;
}

.col-width-3 {
  width: 3vw;
}

.col-width-4 {
  width: 4vw;
}

.col-width-5 {
  width: 5vw;
}

.col-width-10 {
  width: 10vw;
}

.col-width-15 {
  width: 15vw;
}

.col-width-20 {
  width: 20vw;
}

.col-width-25 {
  width: 25vw;
}

.cover-note {
  border-left: 1px solid #A4ACB2;
  margin: 27px 0px 39px 0px;
  position: relative;
  left: 40px;
  padding-left: 38px;

  .attachment-logo {
    position: relative;
  }

  .file-upload-lable {
    position: relative;
    top: -28px;
    left: 10px;
  }

  .file-upload-data {
    .upload-button {
      padding: 0;
      text-align: start !important;
      border-left: none !important;
      background: none !important;
      position: absolute !important;
      display: flex;
      left: 18px;
      right: 0;
      margin-left: -23px;

      &:hover {
        color: #000 !important;
      }
    }

    .upload-text {
      border-radius: 4px !important;
      width: auto !important;
      padding: 0 !important;
    }

    p {
      margin: auto;
      margin-left: 5px;

      font-family: lato-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #17709C;
    }
  }
}

.epod-file {
  margin: 4px 0px 18px 0px;
  position: relative;

  .attachment-logo {
    position: relative;
  }

  .file-uplaod-html {
    border: none;
    box-shadow: none;
  }

  .file-upload-lable {
    position: relative;
  }

  .file-upload-data {
    .upload-button {
      padding: 0;
      text-align: start !important;
      border-left: none !important;
      background: none !important;
      position: absolute !important;
      display: flex;
      left: 18px;
      right: 0;
      margin-left: -19px;
      margin-top: 10px;

      &:hover {
        color: #000 !important;
      }
    }

    .upload-text {
      border-radius: 4px !important;
      width: auto !important;
      padding: 0 !important;
    }

    &.in-line{
      .upload-button {
        margin-left: -10px;
        margin-top: -7px;
      }
      .upload-text {
        height: fit-content;
      }
    }

    p {
      margin: auto;
      margin-left: 5px;

      font-family: lato-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #17709C;
    }
  }
}



.monthly-lane file-upload {
  border: none;
  box-shadow: none;
  position: relative;
  left: 0px !important;
  margin-top: 12px;
}

.monthly-lane .file-upload-data {

  .upload-button {
    padding: 0;
    text-align: start !important;
    border-left: none !important;
    background: none !important;
    position: relative !important;
    display: flex;
    right: 0;

    &:hover {
      color: #000 !important;
    }
  }

  .upload-text {
    border-radius: 4px !important;
    width: auto !important;
    padding: 0 !important;
  }

  p {
    margin: auto;
    margin-left: 5px;

    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #17709C;
  }

}

app-financial-details .file-upload-data {
  .upload-button {
    padding: 0;
    text-align: start !important;
    border-left: none !important;
    background: none !important;
    left: 0;
    position: absolute !important;
    display: flex;

    &:hover {
      color: #000 !important;
    }
  }

  .upload-text {
    border-radius: 4px !important;
    width: auto !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  p {
    margin: auto;
    margin-left: 5px;

    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #17709C;
  }
}

.basic-info-class {
  height: calc(100vmin - 173px);
}

.padding-top-24 {
  padding-top: 24px;
}

.esclationContact .mat-mdc-dialog-container {
  padding: 0;
}

.dialogBoxWindow .mat-mdc-dialog-container {
  padding: 0;
}

.opVehicle .mat-mdc-dialog-container {
  padding: 0;
}

.financialDetails .mat-mdc-dialog-container {
  padding: 0;
}

.referenceCompany .mat-mdc-dialog-container {
  padding: 0;
}

.sub-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #17709C;
}

.sub-text-bar {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: #A4ACB2;
}

.dialog-form-popup-style {
  position: relative;
  padding: 24px 9px 24px 24px;

  .head-text-dialog {
    font-family: lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #00314F;
    margin-top: 0px;
    margin: 0 !important;
  }

  .heading-dialog {
    display: flex;
    justify-content: space-between;

    .closs-arrow {
      cursor: pointer;
    }
  }

  .scrolling-box {
    max-height: calc(100vmin - 278px);
    overflow: auto;
    margin-bottom: 15px;
  }



  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .col-md-12 {
    padding-left: 0;
  }

}

.action-btn-outerdiv {
  border-top: 1px solid rgba(102, 140, 175, 0.2);
}

.padding-hr-24-outerdiv {
  padding: 0 24px 0 24px;
}

.left-right-ends {
  padding-left: 0 !important;
  display: flex !important;
  justify-content: space-between !important;

  .field-note {

    padding: 0;
    margin: 0;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #6D6D6D;
  }

}

.top-border-btn-div {
  padding-top: 30px !important;
  border-top: 1px solid rgba($color: #A4ACB2, $alpha: 0.5);
}

.header-tabs {
  .tab-data {
    padding-left: 32px;
    list-style: none;
    height: 35px;
    margin-bottom: 0px;

    li {
      display: inline-block;
      padding: 0 16px 0 16px;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        border-bottom: 2px solid #00314F;
        padding: 0 16px 0 16px;

        a {
          font-weight: bold;
          color: #00314F !important;
        }

      }

      a {
        text-decoration: none;
        font-family: lato-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.5px;
        color: #17709C;

      }
    }

    li.active {
      background-color: #fff;
      border-bottom: 2px solid #00314F;
      padding: 0 16px 0 16px;

      a {
        font-family: lato-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.5px;
        color: #00314F;
      }
    }
  }

  .sub-tab-data {
    padding: 0;
    list-style: none;
    margin-bottom: 0px;

    li {
      display: inline-block;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        border-bottom: 2px solid #00314F;
        padding: 0 10px;

        a {
          font-weight: bold;
          color: #00314F !important;
        }

      }

      a {
        text-decoration: none;
        font-family: lato-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.5px;
        color: #17709C;

      }
    }

    li.active {
      background-color: #fff;
      border-bottom: 2px solid #00314F;
      padding: 0 10px;

      a {
        font-family: lato-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.5px;
        color: #00314F;
      }
    }
  }
}

.flex-spacebetween {
  display: flex;
  justify-content: space-between;
}

.col-2-width {
  width: 2vw;
}

.col-3-width {
  width: 3vw;
}

.col-4-width {
  width: 4vw;
}

.col-5-width {
  width: 5vw;
}

.col-6-width {
  width: 6vw;
}

.col-7-width {
  width: 7vw;
}

.col-8-width {
  width: 8vw !important;
}

.col-9-width {
  width: 9vw !important;
}

.col-10-width {
  width: 10vw !important;
}

.col-11-width {
  width: 11vw;
}

.col-12-width {
  width: 12vw;
}

.col-13-width {
  width: 13vw;
}

.col-14-width {
  width: 14vw;
}

.col-15-width {
  width: 15vw;
}

.col-16-width {
  width: 16vw;
}

.col-17-width {
  width: 17vw;
}

.col-18-width {
  width: 18vw !important;
}

.col-20-width {
  width: 20vw !important;
}

.col-21-width {
  width: 21vw !important;
}

.col-22-width {
  width: 22vw !important;
}

.col-24-width {
  width: 24vw !important;
}

.col-25-width {
  width: 25vw !important;
}

.col-28-width {
  width: 28vw !important;
}

.col-30-width {
  width: 30vw !important;
}

.col-35-width {
  width: 35vw;
}

.col-32-width {
  width: 32vw;
}

.square-20 {
  width: 20px;
  height: 20px;
}

.width-24 {
  width: 24px;
}

.width-14 {
  width: 14px;
}

.width-18 {
  width: 18px;
}

.square-40 {
  width: 40px;
  height: 40px;
}

//for loader
.loader-loading {
  width: 100%;
  height: 100%;
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  justify-content: center;
}

.whole-view {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #fff, $alpha: 0.4);
}

.side-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2B3C49;
}

.side-text-red {
  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #EB5757;
}

.align-right {
  text-align: right !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-right-6 {
  padding-right: 6px !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.page-count {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6D6D6D;
}

.pagination-margin-top {
  margin-top: 10px;
}

.document-download-link {
  font-family: lato-regular;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #17709C;
  cursor: pointer;
}

.text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 260px;
}

.box-scroll {
  overflow: auto;
  overflow-x: hidden;
}

.box-padding-0-48 {
  padding: 0 24px 0 25px;
}

.green-bar {
  background: rgba($color: #6FCF97, $alpha: 0.2);
  padding: 8px;

  .heading {
    font-family: lato-regular;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #00314F;
    margin: 0;
  }

}

.orange-bar {
  background: #FFE8D1;
  padding: 8px;

  .heading {
    font-family: lato-regular;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #00314F;
    margin: auto 0;
  }

}

.gray-bar {
  background: rgba($color: #828282, $alpha: 0.2);
  padding: 8px;

  .heading {
    font-family: lato-regular;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #00314F;
    margin: 0;
  }

}

.blue-bar {
  background: rgba($color: #2F80ED, $alpha: 0.2);
  padding: 8px;

  .heading {
    font-family: lato-regular;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #00314F;
    margin: 0;
  }

}

.bold-text {
  font-weight: bold;
  font-family: lato-bold;
}

.dialog-box-pop {
  overflow-x: hidden;
  padding: 24px;

  .head-text-dialog {
    font-family: lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #00314F;
  }
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

.margin-left-26 {
  margin-left: 26px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-left-48 {
  margin-left: 48px !important;
}

.width-132 {
  width: 132px;
}

.width-160 {
  width: 160px;
}

.width-170 {
  width: 170px;
}

.width-200 {
  width: 200px;
}

.line-divider {
  margin: 0 12px;
  font-size: 20px;
  line-height: 34px;
  color: #A4ACB2;

}

.time-remain {

  margin-left: 15px;
  padding: 4px 9px;
  background: #EB5757;
  border-radius: 12px;

  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.padding-0-24 {
  padding: 0 24px;
}

.readonly-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #6D6D6D;
}

.readonly-text-12 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6D6D6D;
}

.white-space-pre-line {
  white-space: pre-line;
}

.custom-time-picker {
  position: fixed;
  z-index: 2;
}

.top-margin-neg-45 {
  margin-top: -45px;
}

.base-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B3C49;
}

.base-red-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #EB5757;
}

.visibility-hidden {
  visibility: hidden;
}

.section-sub-heading {
  font-family: 'lato-regular';
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.confirmation-celling-price {
  h2 {
    font-family: Verdana !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    color: #2B3C49;
    margin-bottom: 20px;
  }

  .normal-text {
    font-style: normal !important;
  }

}

.bold-font {
  font-family: lato-bold;
  font-weight: bold;
}

.top-margin-15 {
  margin-top: 15px;
}

.f-right {
  float: right;
}

.col-23-width {
  width: 23vw;
}

.page-contents {
  margin-left: $page-content-margin;
  background-color: $base-background-color;
  padding: 16px 24px;

  .page-heading {
    font-family: $base-font-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: $font-color;
  }

  .section-row-margin-top {
    margin-top: 20px;
  }

  label {
    margin-bottom: 4px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $font-color;
  }

  .section-sub-heading {
    color: $font_color_secondary;
    font-family: Lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .readonly-text {
    margin-bottom: 2px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $readonly-font-color;
  }

  .readonly-side-text {
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6D6D6D;
  }

}

.page-section-heading {
  background: $section-heading-background;
  margin: 0px -26px;
  padding: 9px 26px 10px 26px;

  p {
    font-family: lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #2b3c49;
    margin: 0;
  }

  .second-counter {
    margin-left: 10px !important;
    padding-left: 10px;
    border-left: 2px solid rgba(164, 172, 178, 0.3);
  }
}

.top-border {
  border-top: 1px solid rgba(164, 172, 178, 0.3);
}

.link-text {
  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #17709C;
}

.text-align-end {
  text-align: end;
}

.box-20 {
  width: 20px;
  height: 20px;
}

.all-border {
  border: 1px solid rgba(164, 172, 178, 0.3);
}

.padding-14 {
  padding: 14px;
}

.heading-16 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #2B3C49;
}

.link-text-16 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #17709C;
}

//Basic form without any tabs or search bar //
.basic-form {
  .section-row-margin-top {
    margin-top: 20px;
  }

  label {
    margin-bottom: 4px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $font-color;
  }

  .section-sub-heading {
    color: $link-color;
    font-family: Lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .readonly-text {
    margin-bottom: 2px;
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $readonly-font-color;
  }

  .readonly-side-text {
    font-family: lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6d6d6d;
  }

  margin-top: 16px;
  padding: 0px 26px;
  border: 1px solid rgba(164, 172, 178, 0.3);
  box-sizing: border-box;
  height: calc(100vmin - 170px);
  overflow: auto;

  .btn-div-vertical-padding {
    padding: 20px 0;
  }
}

// Basic Form Over //

// Buttons //
.primary-button {
  @include classic-button(primary, $width: 170);

  &:hover {
    @include button-hover(primary);
  }

  &:disabled {
    @include button-disable(primary);
  }
}

.primary-button-small {
  @include classic-button(primary, $width: 0);

  &:hover {
    @include button-hover(primary);
  }

  &:disabled {
    @include button-disable(primary);
  }
}

.secondary-button {
  @include classic-button(secondary, $width: 170);

  &:hover {
    @include button-hover(secondary);
  }

  &:disabled {
    @include button-disable(secondary);
  }
}

.secondary-button-small {
  @include classic-button(secondary, $width: 0);

  &:hover {
    @include button-hover(secondary);
  }

  &:disabled {
    @include button-disable(secondary);
  }
}

.tertiary-button {
  @include classic-button(tertiary, $width: 0);
}

.no-data-class {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 24px;
  border-left: 1px solid rgba(67, 72, 92, 0.2);
  border-right: 1px solid rgba(67, 72, 92, 0.2) !important;
  padding: 40px 15px;
  text-align: center;
}

.default_dabba_icon {
  width: 60px;
  max-height: 60px;
}

.no-data-border {
  border: 1px solid #dedede !important;
  border-top: transparent !important;
  margin-left: 24px;
  width: calc(100% - 48px);
}

.relative-position {
  position: relative;
}

.no-wrap {
  white-space: nowrap;
}

.bottom-border-dabba {
  border-bottom: 1px solid rgba(43, 60, 73, 0.1) !important;
}

.primary-blue-text {
  color: #00314F;
}

.default-text-link-14 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #17709C;
}

.default-text-link-16 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #17709C;
}

.default-text-blue-16 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #2B3C49;
}

.default-text-blue-14 {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #2B3C49;
}

.default-time-remain {
  font-family: lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #EB5757;
}

.stacked-buttons {
  display: flex;

  p {
    cursor: pointer;
    margin-bottom: 0px;
    padding: 7px 14px 6px 20px;
    border-top: 0.5px solid #A4ACB2;
    border-bottom: 0.5px solid #A4ACB2;
    color: #00314F;

  }

  p.active {
    background: #17709C;
    backdrop-filter: blur(4px);
    color: #ffffff;
    border-color: #17709C;
  }

  p:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 0.5px solid #A4ACB2;
  }

  p:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0.5px solid #A4ACB2;
  }
}

.loader-border-gray {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
}

.highcharts-axis-labels {
  font-weight: bold;
  color: #000;
}

.multislect-additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
  position: relative;
}

//pulse loader
.solidBlink {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: rgb(7, 187, 127);
  position: absolute;
  z-index: 10;
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 1px solid #0d8627;
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65, 0, .34, 1);
  //   z-index:-1;
}

.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: rgb(7, 187, 127);
  box-shadow: 0 0 20px 0 rgb(7, 187, 127);

  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }

  &::after {
    @include rings(3s, 0s);
  }

  &::before {
    @include rings(3s, 0.5s);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(3.5, 3.5, 1);
  }
}

//pulse loader over

//default blank view
.default_blank_view {
  width: 100%;
  min-height: 100px;
  border-left: 1px solid rgba(67, 72, 92, 0.2);
  border-right: 1px solid rgba(67, 72, 92, 0.2);
  display: flex;
  justify-content: center;

  .content {
    margin: auto;

    .text {
      font-family: lato-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #6D6D6D;
    }
  }
}

//border left-right-ends
.border_left_right {
  border-left: 1px solid rgba(67, 72, 92, 0.2);
  border-right: 1px solid rgba(67, 72, 92, 0.2);
}

.agm-info-window-content {
  padding-right: 10px;
}

.gm-ui-hover-effect {
  opacity: 0;
}

// QUILL Editor styling
.qlbt-operation-menu {
  width: 230px !important;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: #fff;

  .qlbt-operation-menu-item {
    padding: 4px 10px;
    display: flex;
    cursor: pointer;
    align-items: center;

    &:hover {
      background: #eee;
    }

    .qlbt-operation-menu-text {
      margin-left: 5px;
    }

    .qlbt-operation-menu-icon {
      display: flex;
    }
  }
}

.qlbt-selection-line {
  display: none !important;
}

.ql-container .qlbt-selection-line.qlbt-selection-line-left::before {
  position: absolute;
  left: -2px;
  top: -2px;
  content: '';
  width: 5px;
  height: 5px;
  background-color: #0589f3;
  border-radius: 5px;
}

.ql-container .qlbt-selection-line.qlbt-selection-line-right::before {
  position: absolute;
  right: -2px;
  bottom: -3px;
  content: '';
  width: 5px;
  height: 5px;
  background-color: #0589f3;
  border-radius: 5px;
}

.quill-better-table-wrapper {
  overflow-x: auto;
}

table.quill-better-table {
  table-layout: fixed;
  border-collapse: collapse;

  tr:first-child td {
    background: #F4F5F7;
  }
}

table.quill-better-table td {
  border: 1px solid #C2C7CF;
  padding: 5px 8px;
}

.qlbt-operation-menu {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  z-index: 100;
  overflow: hidden;
}

.qlbt-operation-menu .qlbt-operation-menu-dividing {
  height: 1px;
  background-color: #efefef;
}

.qlbt-operation-menu .qlbt-operation-menu-subtitle {
  color: #999;
  font-size: 14px;
  padding: 5px 16px;
}

.qlbt-operation-menu .qlbt-operation-color-picker {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px 10px;
  background-color: #fff;
  overflow: hidden;
}

.qlbt-operation-menu .qlbt-operation-color-picker .qlbt-operation-color-picker-item {
  width: 20px;
  height: 20px;
  border: 1px solid #595959;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.qlbt-operation-menu .qlbt-operation-menu-item {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background-color: #fff;
  cursor: pointer;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
}

.qlbt-operation-menu .qlbt-operation-menu-item:hover {
  background-color: #efefef;
}

.qlbt-operation-menu .qlbt-operation-menu-item .qlbt-operation-menu-icon {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  font-size: 0;
}

.qlbt-col-tool {
  position: absolute;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  z-index: 99;
  height: 16px;
}

.qlbt-col-tool .qlbt-col-tool-cell {
  position: relative;
  background-color: #fff;
  border-top: 1px solid #C2C7CF;
  border-right: 1px solid #C2C7CF;
  border-bottom: 1px solid #C2C7CF;
}

.qlbt-col-tool .qlbt-col-tool-cell:first-child {
  border-left: 1px solid #C2C7CF;
}

.qlbt-col-tool .qlbt-col-tool-cell-holder {
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  z-index: 3;
  width: 1px;
  background-color: transparent;
  cursor: ew-resize;
}

.qlbt-col-tool .qlbt-col-tool-cell-holder:hover {
  background-color: #0589f3;
}

.qlbt-col-tool .qlbt-col-tool-cell-holder::before {
  content: "";
  position: absolute;
  top: 0;
  left: -6px;
  display: block;
  width: 8px;
  height: 100%;
}

.qlbt-col-tool .qlbt-col-tool-cell-holder::after {
  content: "";
  position: absolute;
  top: 0;
  right: -6px;
  display: block;
  width: 8px;
  height: 100%;
}

// QUILL Editor styling Ends


//map-box styling

.mapboxgl-popup-content {
  font-family: lato-regular;
  max-height: auto !important;
  overflow: auto;
}

.mapboxgl-popup {
  min-width: 250px;
  max-width: 500px !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: unset!important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled){
  opacity: 0;
  z-index: 11;
  padding: 0!important;
  width: 30px;
  min-width: 30px!important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label{
  font-family: lato-regular!important;
  font-size: 14px!important;
  line-height: 14px!important;
  color: #3e3e3e!important;
}

.auth-success-snack-bar {
  background-color: #E8FBE8;
}

.auth-success-snack-bar .mat-mdc-simple-snack-bar {
  &:before {
    content: url(/assets/images/success-snack.svg);
    position: relative;
    margin: 14px 10px 0 10px;
  }

  &:after {
    content: url(/assets/images/cross-button.svg);
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    z-index: 10;
  }
}

.auth-error-snack-bar {
  background-color: #FFE0E1;
}

.auth-error-snack-bar .mat-mdc-simple-snack-bar {
  &:before {
    content: url(/assets/images/error-snackbar.svg);
    position: relative;
    margin: 14px 10px 0 10px;
  }

  &:after {
    content: url(/assets/images/cross-button.svg);
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    z-index: 10;
  }
}

.input-field-full-width{
  width: 100%;
}

.timepicker-overlay{
  z-index: 99999999 !important;
}

.time-range{
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
      display: flex!important;
  }
}

.ngx-material-timepicker-toggle{
  padding: 4px 0!important;
  img{
    width: 14px;
  }
}

// drag and reposition
.drag-and-position{
  .drag-list {
    width: fit-content;
    max-width: 100%;
    border: solid 1px var(--border-light-gray);
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .drag-box {
    padding: 3px 28px 3px 12px;
    border-bottom: solid 1px var(--border-light-gray);
    color: #3e3e3e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 12px;

    .item-row{
      display: flex;
      align-items: center;
      gap: 10px;
      img{
        width: 10px;
      }
    }
  }
  
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .drag-box:last-child {
    border: none;
  }
  
  .drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
