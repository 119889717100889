// base styling variables
$base-background-color: #fff;
$font-color: #00314F;
$font_color_secondary: #2B3C49;
$link-color: #17709C;
$readonly-font-color: #6D6D6D;
$infocused-text: #A4ACB2;
$highlight-font-color: #F17922;
$error-font-color: #eb5757;
$border-gray: #a4acb2;
$border_light_gray: rgba(67, 72, 92, 0.2);
$placeholder_font: #A4ACB2;
$input_border_highlight: rgba($color: #17709C, $alpha: 0.7);

//fonts
$base-font-regular: lato-regular;
$base-font-bold: lato-bold;

// primary button
$primary-button-color: #17709C;
$primary-button-font-color: #fff;
$primary-button-color-hover: rgba($color: #17709C, $alpha: 0.7);
$primary-button-color-disabled: rgba($color: #87A5C1, $alpha: 0.3);
// $primary-button-disabled-font-color: #87A5C1;
$primary-button-disabled-font-color: #7d7d7d;

//secondary button
$secondary-button-color: #fff;
$secondary-button-font-color: #17709C;
$secondary-button-color-hover: rgba(135, 165, 193, 0.1);
$secondary-button-color-disabled: rgba($color: #A4ACB2, $alpha: 0.4);

//contrast button
$contrast-button-color: #F17922;
$contrast-button-font-color: #ffffff;
$contrast-button-color-hover: rgba($color: #F17922, $alpha: 0.7); ;
$contrast-button-color-disabled: rgba($color: #87A5C1, $alpha: 0.3);

//tertiary button 
$tertiary-button-font-color: #17709C;

// Custom Margins
$page-content-margin: 0px;

// Backgrounds 
$table_header_background: #f9f9f9;
$table_row_background: #ffffff;
$section-heading-background: rgba(102,140,175,0.1);

//Borders 
$basic-border-color:  rgba(164,172,178,0.3);

// hover over 
$row_hover_over_color: rgba($color: #87A5C1, $alpha: 0.15);

//ff_green
$ff_green: #219653;
$ff_pending_blue: #2196F3;